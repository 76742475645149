
import { Component, Prop, Vue } from "vue-property-decorator";
import moment from "moment";

type Task = {
  id?: number;
  status_id: number;
  status?: {
    id: number;
    name: string;
  };
  date_from: string;
  info: string;
  order: {
    key_deposited?: string;
    description?: string;
  };
};

@Component({
  filters: {
    date_from(value) {
      if (!value) return;

      return moment(value).format("Do MMMM, dddd");
    }
  }
})
export default class ScheduledTasksComponent extends Vue {
  @Prop(Array) readonly tasks!: Task[];
  @Prop(Object) readonly task!: Task;

  expansionPanel: number;

  constructor() {
    super();

    this.expansionPanel = this.hasNewStatus ? 0 : -1;
  }

  get tasksSortedByDate() {
    const tasksSortedByDate = this.tasks
      ?.slice()
      .sort((a, b) => moment(a.date_from).unix() - moment(b.date_from).unix());

    return tasksSortedByDate.filter(task => this.nowIsBefore(task.date_from));
  }

  get hasNewStatus() {
    return this.task.status_id == 1;
  }

  nowIsBefore(date: string): boolean {
    return moment().isBefore(moment(date));
  }
}
